import {displayCategories, displayCategory} from "@/utils/accessory-utils";

export const accessoryMixin = {
  data: function () {
    return {
      icons: [
        { title: this.$t('accessory.icons.icon-1'), value: "ic_air_conditioning", type: 4 },
        { title: this.$t('accessory.icons.icon-2'), value: "ic_air_purifier", type: 4 },
        { title: this.$t('accessory.icons.icon-3'), value: "ic_airquality", type: 4 },
        { title: this.$t('accessory.icons.icon-4'), value: "ic_audio1_playing", type: 10 },
        { title: this.$t('accessory.icons.icon-5'), value: "ic_audio2_playing", type: 10 },
        { title: this.$t('accessory.icons.icon-6'), value: "ic_battery", type: 10 },
        { title: this.$t('accessory.icons.icon-7'), value: "ic_co2", type: 4 },
        { title: this.$t('accessory.icons.icon-8'), value: "ic_coffe_machine", type: 10 },
        { title: this.$t('accessory.icons.icon-9'), value: "ic_dishwasher", type: 10 },
        { title: this.$t('accessory.icons.icon-10'), value: "ic_dnd", type: 10 },
        { title: this.$t('accessory.icons.icon-11'), value: "ic_door_lock", type: 10 },
        { title: this.$t('accessory.icons.icon-12'), value: "ic_dry_clean", type: 10 },
        { title: this.$t('accessory.icons.icon-13'), value: "ic_electricity", type: 6 },
        { title: this.$t('accessory.icons.icon-14'), value: "ic_electricity_usage", type: 6 },
        { title: this.$t('accessory.icons.icon-15'), value: "ic_humidity", type: 4 },
        { title: this.$t('accessory.icons.icon-16'), value: "ic_hvac_air_conditioning", type: 4 },
        { title: this.$t('accessory.icons.icon-17'), value: "ic_hvac_combi", type: 4 },
        { title: this.$t('accessory.icons.icon-18'), value: "ic_hvac_fan", type: 4 },
        { title: this.$t('accessory.icons.icon-19'), value: "ic_hvac_radiator", type: 4 },
        { title: this.$t('accessory.icons.icon-20'), value: "ic_hvac_thermometer", type: 4 },
        { title: this.$t('accessory.icons.icon-21'), value: "ic_hvac_thermostat", type: 4 },
        { title: this.$t('accessory.icons.icon-22'), value: "ic_hvac_underfloor", type: 4 },
        { title: this.$t('accessory.icons.icon-23'), value: "ic_indoor_temp", type: 4 },
        { title: this.$t('accessory.icons.icon-24'), value: "ic_ipcamera", type: 3 },
        { title: this.$t('accessory.icons.icon-25'), value: "ic_kettle", type: 10 },
        { title: this.$t('accessory.icons.icon-26'), value: "ic_light2", type: 1 },
        { title: this.$t('accessory.icons.icon-27'), value: "ic_lighting_bulb", type: 1 },
        { title: this.$t('accessory.icons.icon-28'), value: "ic_lighting_ceiling", type: 1 },
        { title: this.$t('accessory.icons.icon-29'), value: "ic_lighting_ceiling2", type: 1 },
        { title: this.$t('accessory.icons.icon-30'), value: "ic_lighting_chandelier", type: 1 },
        { title: this.$t('accessory.icons.icon-31'), value: "ic_lighting_chandelier2", type: 1 },
        { title: this.$t('accessory.icons.icon-32'), value: "ic_lighting_desktop", type: 1 },
        { title: this.$t('accessory.icons.icon-33'), value: "ic_lighting_lambader", type: 1 },
        { title: this.$t('accessory.icons.icon-34'), value: "ic_lighting_lambader2", type: 1 },
        { title: this.$t('accessory.icons.icon-35'), value: "ic_makeup_room", type: 10 },
        { title: this.$t('accessory.icons.icon-36'), value: "ic_microvawe", type: 10 },
        { title: this.$t('accessory.icons.icon-37'), value: "ic_modem", type: 10 },
        { title: this.$t('accessory.icons.icon-38'), value: "ic_onoff", type: 6 },
        { title: this.$t('accessory.icons.icon-39'), value: "ic_oven", type: 10 },
        { title: this.$t('accessory.icons.icon-40'), value: "ic_pm25", type: 10 },
        { title: this.$t('accessory.icons.icon-41'), value: "ic_projection_board", type: 10 },
        { title: this.$t('accessory.icons.icon-42'), value: "ic_refrigerator", type: 10 },
        { title: this.$t('accessory.icons.icon-43'), value: "ic_room_service", type: 10 },
        { title: this.$t('accessory.icons.icon-44'), value: "ic_shutter_curtain", type: 5 },
        { title: this.$t('accessory.icons.icon-45'), value: "ic_shutter_garage", type: 5 },
        { title: this.$t('accessory.icons.icon-46'), value: "ic_shutter", type: 5 },
        { title: this.$t('accessory.icons.icon-47'), value: "ic_shutter2", type: 5 },
        { title: this.$t('accessory.icons.icon-48'), value: "ic_solar_panel", type: 10 },
        { title: this.$t('accessory.icons.icon-49'), value: "ic_speaker", type: 10 },
        { title: this.$t('accessory.icons.icon-50'), value: "ic_stove", type: 10 },
        { title: this.$t('accessory.icons.icon-51'), value: "ic_switch_electric", type: 6 },
        { title: this.$t('accessory.icons.icon-52'), value: "ic_switch_irrigation", type: 6 },
        { title: this.$t('accessory.icons.icon-53'), value: "ic_switch_onoff", type: 6 },
        { title: this.$t('accessory.icons.icon-54'), value: "ic_switch_socket", type: 6 },
        { title: this.$t('accessory.icons.icon-55'), value: "ic_switch_valve", type: 6 },
        { title: this.$t('accessory.icons.icon-56'), value: "ic_thermostat_neutral", type: 4 },
        { title: this.$t('accessory.icons.icon-57'), value: "ic_tv", type: 10 },
        { title: this.$t('accessory.icons.icon-58'), value: "ic_vaccum_cleaner", type: 10 },
        { title: this.$t('accessory.icons.icon-59'), value: "ic_voc", type: 10 },
        { title: this.$t('accessory.icons.icon-60'), value: "ic_washing_mach", type: 10 },
        { title: this.$t('accessory.icons.icon-61'), value: "ic_wifi", type: 10 },
        { title: this.$t('accessory.icons.icon-62'), value: "ic_sensor_door", type: 2 },
        { title: this.$t('accessory.icons.icon-63'), value: "ic_sensor_fire", type: 2 },
        { title: this.$t('accessory.icons.icon-64'), value: "ic_sensor_gas", type: 2 },
        { title: this.$t('accessory.icons.icon-65'), value: "ic_sensor_motion", type: 2 },
        { title: this.$t('accessory.icons.icon-66'), value: "ic_sensor_water", type: 2 },
        { title: this.$t('accessory.icons.icon-67'), value: "ic_sensor_window", type: 2 },



        // { title: this.$t('accessory.icons.icon-50'), value: "ic_solarpower", type: 10 },
        // { title: this.$t('accessory.icons.icon-51'), value: "ic_speaker_on", type: 10 },
        // { title: this.$t('accessory.icons.icon-32'), value: "ic_electricity2_on", type: 6 },
        // { title: this.$t('accessory.icons.icon-33'), value: "ic_gridpower", type: 6 },
        // { title: this.$t('accessory.icons.combi'), value: "ic_hvac_combi", type: 4 },
        // { title: this.$t('accessory.icons.radiator'), value: "ic_hvac_radiator", type: 4 },
        // { title: this.$t('accessory.icons.thermometer'), value: "ic_hvac_thermometer", type: 4 },
        // { title: this.$t('accessory.icons.underfloor-heating'), value: "ic_hvac_underfloor", type: 4 },
        // { title: this.$t('accessory.icons.ceiling-lighting'), value: "ic_lighting_ceiling", type: 1 },
        // { title: this.$t('accessory.icons.ceiling-lighting-2'), value: "ic_lighting_ceiling2", type: 1 },
        // { title: this.$t('accessory.icons.light-pulb'), value: "ic_lighting_bulb", type: 1 },
        // { title: this.$t('accessory.icons.chandelier'), value: "ic_lighting_chandelier", type: 1 },
        // { title: this.$t('accessory.icons.desktop-light'), value: "ic_lighting_desktop", type: 1 },
        // { title: this.$t('accessory.icons.lambader'), value: "ic_lighting_lambader", type: 1 },
        // { title: this.$t('accessory.icons.shutter'), value: "ic_shutter", type: 5 },
        // { title: this.$t('accessory.icons.shutter-2'), value: "ic_shutter2", type: 5 },
        // { title: this.$t('accessory.icons.curtain'), value: "ic_shutter_curtain", type: 5 },
        // { title: this.$t('accessory.icons.garage'), value: "ic_shutter_garage", type: 5 },
        // { title: this.$t('accessory.icons.on-off-control'), value: "ic_switch_onoff", type: 6 },
        // { title: this.$t('accessory.icons.cable'), value: "ic_switch_cable", type: 6 },
        // { title: this.$t('accessory.icons.electricity'), value: "ic_switch_electric", type: 6 },
        // { title: this.$t('accessory.icons.irrigation'), value: "ic_switch_irrigation", type: 6 },
        // { title: this.$t('accessory.icons.wall-plug'), value: "ic_switch_socket", type: 6 },
        // { title: this.$t('accessory.icons.valve'), value: "ic_switch_valve", type: 6 },
        // { title: this.$t('accessory.icons.door-sensor'), value: "ic_sensor_door", type: 2 },
        // { title: this.$t('accessory.icons.motion-sensor'), value: "ic_sensor_motion", type: 2 },
        // { title: this.$t('accessory.icons.smoke-sensor'), value: "ic_sensor_smoke", type: 2 },
        // { title: this.$t('accessory.icons.water-leak-sensor'), value: "ic_sensor_water", type: 2 },
        // { title: this.$t('accessory.icons.ip-camera'), value: "ic_ipcamera", type: 3 },
        // { title: this.$t('accessory.icons.icon-27'), value: "ic_dishwasher", type: 10 },
        // { title: this.$t('accessory.icons.icon-28'), value: "ic_dnd", type: 10 },
        // { title: this.$t('accessory.icons.icon-29'), value: "ic_dry_clean", type: 10 },
        // { title: this.$t('accessory.icons.icon-30'), value: "ic_hvac_fan", type: 4 },
        // { title: this.$t('accessory.icons.icon-31'), value: "ic_hvac_thermostat", type: 4 },
        // { title: this.$t('accessory.icons.icon-32'), value: "ic_kettle", type: 10 },
        // { title: this.$t('accessory.icons.icon-33'), value: "ic_lighting_chandelier2", type: 1 },
        // { title: this.$t('accessory.icons.icon-34'), value: "ic_makeup_room", type: 10 },
        // { title: this.$t('accessory.icons.icon-35'), value: "ic_microvawe", type: 10 },
        // { title: this.$t('accessory.icons.icon-36'), value: "ic_oven", type: 10 },
        // { title: this.$t('accessory.icons.icon-37'), value: "ic_refrigerator", type: 10 },
        // { title: this.$t('accessory.icons.icon-38'), value: "ic_room_service", type: 10 },
        // { title: this.$t('accessory.icons.icon-39'), value: "ic_sensor_gas", type: 2 },
        // { title: this.$t('accessory.icons.icon-40'), value: "ic_sensor_window", type: 2 },
        // { title: this.$t('accessory.icons.icon-41'), value: "ic_speaker", type: 10 },
        // { title: this.$t('accessory.icons.icon-42'), value: "ic_stove", type: 10 },
        // { title: this.$t('accessory.icons.icon-43'), value: "ic_tv", type: 10 },
        // { title: this.$t('accessory.icons.icon-44'), value: "ic_washing_mach", type: 10 },
      ]
    }
  },

  computed: {
    displayCategories(){
      return displayCategories
    },
  },

  methods: {
    onImageError: function(e){
      e.target.src = "/assets/icons/ic_switch_onoff.png";
    },

    displayCategory
  }
}